<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Layout Fonts'" :title="'Layout Fonts List'">
      <div class="list__buttons">
        <div class="list__search">
          <InputSearch
            :search="handleSearch"
            :id="'search'"
            :label="'search'"
            :placeholder="'search...'"
            :setValue="filterData"
          />
        </div>
        <MainButton @click.native="handleCreate()">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="layoutFontsList.length">
          <CustomTable
            v-if="layoutFontsList.length"
            :data="layoutFontsList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="handleDelete"
            :handle-edit="handleEdit"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :order="orderSort"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="layoutFonts.lastPage"
              :page="page"
              :dataList="layoutFonts"
              :urlList="'LAYOUT_FONTS_LIST'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Layout Font found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Layout Font - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Layout Font {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";

export default {
  name: "LayoutFontsList",
  metaInfo: {
    title: "Layout Fonts List",
  },
  data() {
    return {
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "id",
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
        ],
        customOrder: ["id", "name", "null"],
      },
      filterData: {
        search: null,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputSearch,
  },
  mounted() {
    this.setQueryParams();
    // this.getList();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("references", ["error", "loadingProcess", "layoutFonts", "layoutFontsList"]),
    page() {
      return +this.$route.params.page;
    },
    getQueryParameters() {
      const query = this.$route.fullPath.split("?")[1];
      if (query) {
        return `?${query}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.filterData.search != null) {
        const data = {};
        data.search = this.filterData.search;
        this.$store.commit("references/setLoading", true);
        referencesApi
          .filterLayoutFonts(`?page=${this.page}`, data)
          .then((res) => {
            this.$store.commit("references/setError", {});
            this.$store.commit("references/setValidationErrors", {});
            this.$store.commit("references/setLayoutFontsList", res.data.items.data);
            this.$store.commit("references/setLayoutFonts", res.data.items);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("references/setLoading", false);
          });
      } else {
        this.$store.dispatch("references/getLayoutFonts", url);
      }
    },
    handleGetQueryParam(param) {
      if (this.$route.query[param] != undefined) {
        return this.$route.query[param];
      } else {
        return null;
      }
    },
    setQueryParams() {
      if (this.handleGetQueryParam("search")) {
        this.filterData.search = this.handleGetQueryParam("search");
      }

      this.getList();
    },
    handleSetQueryParams() {
      const filter = {};
      if (this.filterData.search != null) {
        filter.search = this.filterData.search;
      }
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.LAYOUT_FONTS_LIST.replace(":page", 1),
        });
      }
      this.$router.push({
        name: "LayoutFontsList",
        query: filter,
      });
    },
    handleSearch() {
      this.handleSetQueryParams();
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.LAYOUT_FONTS_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.LAYOUT_FONTS_CREATE,
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}`;
      this.$store.commit("references/setLoading", true);
      referencesApi
        .deleteLayoutFonts(url)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__buttons {
    display: flex;
  }
  &__search {
    min-width: 250px;
    margin-right: 20px;
  }
}
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr !important;
}
</style>
